import Vue from 'vue';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import {BVToastPlugin} from 'bootstrap-vue';

import store from '@/store';
import App from '@/views/Login/App';

import '@/utils/proto';

Vue.config.productionTip = false;
Vue.use(VueRouter);
// messages
Vue.use(PortalVue);
Vue.use(BVToastPlugin);

const routes = [
  {
    path: '/account/password/reset',
    component: () => import('@/views/Login/Reset'),
    name: 'password-reset',
  },
  {
    path: '/account/password/reset/confirm/:user_id/:timestamp/:signature/',
    component: () => import('@/views/Login/Confirm'),
    name: 'password-reset-confirm',
  },
  {
    path: '/account/login',
    component: () => import('@/views/Login/Login'),
    name: 'login', props: (route) => ({...route.query, ...route.params}),
  },
  {
    path: '/account/signup',
    component: () => import('@/views/Login/SignUp'),
    name: 'sign-up', props: true,
    meta: {disabled: process.env.VUE_APP_DISABLE_REGISTRATION === 'true' || process.env.VUE_APP_DISABLE_REGISTRATION === '1'}
  },
  {
    path: '/account/signup/complete',
    component: () => import('@/views/Login/SignUpComplete'),
    name: 'sign-up-complete', props: true,
  },
  {
    path: '/account/signup/verify/:user_id/:timestamp/:signature/',
    component: () => import('@/views/Login/SignUpVerify'),
    name: 'sign-up-verify', props: true,
  },
  {
    path: '/account/invite/:payload/:timestamp/:signature/',
    component: () => import('@/views/Login/InviteVerify'),
    name: 'invite-verify',
  },
  {
    path: '/account/email/verify/:userId/:timestamp/:signature/:email',
    component: () => import('@/views/Login/ChangeEmailVerify'),
    name: 'change-email-verify',
  }
];
const router = new VueRouter({routes, mode: 'history'});

router.beforeEach((to, from, next) => {
  if (to.meta?.disabled && from.name !== 'invite-verify') {
    next({name: 'login'});
  }
  next();
});

new Vue({router, render: (h) => h(App), store}).$mount('#app');
